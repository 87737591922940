import {
	CheckCircleFilled,
	CloseCircleFilled,
	EyeOutlined,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
	StarFilled,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Select, Space, Spin, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import { Link } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import WeightChange from 'src/components/ui/weight-change';
import {
	CmsDeleteArea,
	CmsDeleteAreaVariables,
} from 'src/lib/gql/generated/CmsDeleteArea';
import { CmsGetDiscoverTheCity } from 'src/lib/gql/generated/CmsGetDiscoverTheCity';
import {
	CmsUpdateArea,
	CmsUpdateAreaVariables,
} from 'src/lib/gql/generated/CmsUpdateArea';
import { MutationDeleteArea, MutationUpdateArea } from 'src/lib/gql/mutations';
import { QueryDiscoverTheCity } from 'src/lib/gql/queries';
import { DiscoverCityTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { ROUTES } from '../config/route';

const DiscoverCity = () => {
	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetDiscoverTheCity>(QueryDiscoverTheCity, {
		fetchPolicy: 'cache-and-network',
	});

	const [updateAreas] = useMutation<CmsUpdateArea, CmsUpdateAreaVariables>(
		MutationUpdateArea,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Discover the City',
					pagePrefix: 'Area',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const [deleteArea] = useMutation<CmsDeleteArea, CmsDeleteAreaVariables>(
		MutationDeleteArea,
		{
			onError(error) {
				CustomNotification({
					pageName: 'Discover the City',
					pagePrefix: 'Area',
					notificationType: 'custom-error',
					customDescription: error.message,
				});
			},
		},
	);

	const deleteCity = async (id: string) => {
		if (!confirm('Confirm delete?')) return;
		const rsp = await deleteArea({
			variables: {
				id: id,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Discover the City',
				pagePrefix: 'Area',
				notificationType: 'deleted',
			});

			refetchQuery();
		}
	};
	const updateCity = async (
		id: string,
		enabled: boolean | undefined,
		pos: number | null | undefined,
		featured: boolean | undefined,
	) => {
		const rsp = await updateAreas({
			variables: {
				id: id,
				enabled: enabled,
				weight: pos,
				featured: featured,
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Discover the City',
				pagePrefix: 'Area',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	};

	const p_DiscoverCity: DiscoverCityTable[] = [];

	const p_DiscoverCity_Query = qryData?.getAreas?.forEach((area, i) => {
		return p_DiscoverCity.push({
			id: area?.id || '-1',
			key: (i + 1).toString() || '',
			name: area?.name || '',
			tag: area?.hTag || '',
			enabled: area?.enabled || false,
			weight: area?.weight || 0,
			slug: area?.slug || '',
			featured: area?.featured || false,
		});
	});

	// p_DiscoverCity.sort((a, b) => a.weight - b.weight);

	// Fields are awesome
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Position',
			width: 80,
			sorter: (a, b) => a.weight - b.weight,
			render: ({ id, weight }: { id: string; weight: number }) => {
				return (
					<WeightChange
						pos={weight}
						onSubmitChange={(weight) => {
							updateCity(id, undefined, weight, undefined);
						}}
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'name',
			key: 'title',
			width: 200,
			...tableColumnTextFilterConfig<DiscoverCityTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
			sorter: (a, b) => {
				return a.name.length - b.name.length;
			},
		},

		{
			title: 'Tag',
			key: 'tag',
			dataIndex: 'tag',
			width: 200,
			ellipsis: true,
			align: 'center',
			filters: qryData?.getAreas?.map((area) => {
				return {
					text: area.hTag,
					value: area.hTag,
				};
			}),
			onFilter: (value, record) => record.tag.indexOf(value) === 0,
			render: (tag: string) => (
				<Tag
					color={'geekblue'}
					key={tag}
					style={{
						margin: '2px',
						display: 'block',
					}}
				>
					{tag.toUpperCase()}
				</Tag>
			),
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => record.enabled === value,
			render: (props: {
				enabled: boolean;
				id: string;
				slug: string;
				featured: boolean;
			}) => {
				const { enabled, id, slug, featured } = props;

				return (
					<Space>
						<a
							href={`https://dubainight.com/city/${slug}`}
							target="_blank"
							rel="noreferrer"
						>
							<EyeOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</a>
						<StarFilled
							onClick={() => {
								updateCity(id, undefined, undefined, !featured);
							}}
							style={{
								color:
									featured === true ? 'var(--highlighted)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
							title="Featured"
						/>
						<Link to={`${ROUTES.EDIT_DISCOVER_CITY.path}/${id}`}>
							<FormOutlined
								style={{
									color: 'black',
									width: '12px',
									height: '12px',
								}}
							/>
						</Link>
						<CheckCircleFilled
							onClick={() => {
								updateCity(id, !enabled, undefined, undefined);
							}}
							style={{
								color: enabled === true ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={() => {
								deleteCity(id);
							}}
							style={{
								color: 'var(--invalid)',
								width: '12px',
								height: '12px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	const { Option } = Select;

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Discover the City</Text>
			</Col>

			<Col span={24}>
				<Row
					gutter={16}
					style={{
						margin: '16px 0',
					}}
					align={'bottom'}
				>
					<Link to={ROUTES.CREATE_DISCOVER_CITY.path}>
						<Button className="btn-main">
							Create New City
							<PlusOutlined
								style={{
									position: 'relative',
									top: '1.5px',
								}}
							/>
						</Button>
					</Link>
				</Row>

				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_DiscoverCity}
						columnData={columns}
						onChange={() => {
							//
						}}
						other={{
							scroll: {
								x: 900,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default DiscoverCity;
